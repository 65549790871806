import { Carousel } from "@material-tailwind/react";


const HomeCarousel = ({images}) => {
    return (
    <>
    <Carousel 
        autoplay={true}
        autoplayDelay={4000}
        loop={true}
        transition={{type:'spring', duration: 2}}
        >
        {
            images.map(image => {
                return (
                    <img 
                    key={image.key}
                    src={image.url} 
                    alt={image.description}
                    className="w-full h-full object-cover"
                    />
                )
            })
        }
    </Carousel>
    </>
    )
}

export default HomeCarousel;