



const ContactInfo = () => {
    return (
        <>
        <div>
        <div className="mb-6 p-4">
                <h1 className="text-text font-semibold text-lg mb-2">Customer Service</h1>
                <p className="text-text font-normal text-sm mb-1"><span className="font-semibold">Email: </span>contact@globenmap.com</p>
            </div>
            
            <div className="mb-6 p-4">
                <h1 className="text-text font-semibold text-lg mb-2">Location</h1>
                <p className="text-text font-normal text-sm mb-1">Windsor, Ontario</p>
                <p className="text-text font-normal text-sm mb-1">Canada N9J 1V1</p>
            </div>      
        </div>
        </>
    )
}

export default ContactInfo;