import { Drawer } from '@mui/material';
import { RxCross1 } from 'react-icons/rx';
import CheckoutItem from './CheckoutItem';
import { useState, useEffect } from 'react';
import stripeRequest from '../Utils/Stripe/stripe';
import { sizes } from '../PosterDesigner/utils/styles';
import addDocToFireStore from '../Utils/Firebase/FirebaseConfig';
import { currencyLogos } from '../PosterDesigner/utils/styles';
import './spinner.css';

const DrawerBar = ({Open, toggleDrawer, cart, setCart, currency, rates}) => {
    
    const [ total, setTotal ] = useState(null);
    const [ isLoading, setIsLoading ] = useState(false);

    const handleCheckout = async (cart) =>{
      setIsLoading(true);
      const items = cart.map(item => {
        
        if (item.Format === 'Digital') {
          const digitalPriceKey = 'price_1QRN8RRruZcEpy4u7mmh6REW'
          return {
            price: digitalPriceKey,
            quantity: item.quantity,
          }
        } else {
          const priceKey = sizes.find(sizeItem => sizeItem.size === item.Size)
          return {
            price: priceKey.priceId,
            quantity: item.quantity,
          } 
        }
      });
      const docRefId =  await addDocToFireStore(cart)
      
      const body = {
        items: items,
        metaData: docRefId
      }

       try {
        await stripeRequest(body)
      } catch(err){
        console.error("Stripe Payment Error:", err)
      } finally {
        setIsLoading(false);
      }
    }

    useEffect(()=> {
        if (cart && cart.length > 0){
        setTotal(cart.reduce((total, item) => total + ((item.Price * item.quantity*rates[currency])/rates[item.currency]),0));
        } else{
          setTotal(null)
        }
    },[cart, currency, rates])

    return (
      <div>
        <Drawer
          anchor="right"
          open={Open}
          onClose={toggleDrawer}
          sx={{
            '& .MuiDrawer-paper': {
              backgroundColor: '#F2F5EF',
              width: {xs: '90%', sm: '60%', md: '50%', lg: '450px'},
            },
          }}
        >
        <div className='flex items-center justify-between py-4 px-6 bg-accent/30'>
        <h1 className='text-2xl font-bold text-text'>Your Cart</h1>
        <RxCross1 className='cursor-pointer' onClick={toggleDrawer}/>
        </div>

        <div className='w-full flex flex-col items-center justify-between h-screen bg-accent/30'>
            <div className='w-full'>    
            <CheckoutItem cart={cart} setCart={setCart} currency={currency} rates={rates}/>
            </div>
            <div className='flex flex-wrap justify-between gap-2 items-center bg-accent w-full px-4'>
                <div className='font-semibold text-text'>Subtotal: {currencyLogos[currency]}{total ? total.toFixed(2) : ''}</div>
                <button 
                onClick={()=> {handleCheckout(cart)}} 
                disabled={isLoading}
                className={`text-sm lg:text-base font-semibold rounded-sm bg-primary text-white hover:shadow-xl hover:bg-secondary hover:text-text py-2 px-12 my-4`}>
                  {isLoading ? <div className="spinner"></div> : 'Checkout'}
                </button>
            </div>
        </div>
        </Drawer>
      </div>
    )
  }

export default DrawerBar;