import { useRef } from "react";
import { emailJs } from "../Utils/NodeMailer/nodeMailer";

const ContactForm = ({contactForm, setContactForm, setSubmitted}) => {

    const form = useRef();

    const handleChange = (e) => {
        const { name, value } = e.target;
        
        setContactForm((prevState)=>({
            ...prevState,
            [name]: value
          }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (contactForm.name && contactForm.email && contactForm.message) {
            await emailJs(form.current, 'template_s143nab');
        }
        setSubmitted(true);
        
    }

    return (
        <>
        <form ref={form} onSubmit={handleSubmit} className='rounded-sm text-text py-4 px-6 border border-accent'>
        <label><h1 className='pb-2 text-text font-semibold text-xl'>Contact Us</h1></label>
        <label><h1 className='pb-2 text-text font-light text-base'>If you have questions or special inquiries, you're welcome to contact us or fill out this form</h1></label>

        <div className='flex md:flex-wrap flex-col md:flex-row md:space-x-4'>
        <div className='mb-5 flex-1'>
        <label className='block m-1 text-sm font-normal text-text' htmlFor='name'>Name *</label>
        <input value = {contactForm.name} onChange={handleChange} type="text" name="name" placeholder="Full Name" required className="block w-full p-2.5 text-sm bg-background rounded-sm border border-accent" />
        </div>
        </div>

        <div className='flex md:flex-wrap flex-col md:flex-row md:space-x-4'>
        <div className='mb-5 flex-1'>
        <label className='block m-1 text-sm font-normal text-text' htmlFor='email'>Email *</label>
        <input value = {contactForm.email} onChange={handleChange} type="email" name="email" placeholder="Email" required className="block w-full p-2.5 text-sm bg-background rounded-sm border border-accent " />
        </div>
        <div className='mb-5 flex-1'>
        <label className='block m-1 text-sm font-normal text-text   ' htmlFor='phone'>Phone</label>
        <input value = {contactForm.phone} onChange={handleChange} type="text" name="phone" placeholder="Phone" className="block w-full p-2.5 text-sm bg-background rounded-sm border border-accent" />
        </div>
        </div>

        <div className='flex md:flex-wrap flex-col md:flex-row md:space-x-4'>
        <div className='mb-5 flex-1'>
        <label className='block m-1 text-sm font-normal text-text' htmlFor='message'>Message *</label>
        <textarea value = {contactForm.message} onChange={handleChange} name="message" placeholder="How can we help?" required className="block w-full p-2.5 text-sm bg-background rounded-sm border border-accent" rows='5'></textarea>
        </div>
        </div>
        <button type='submit' className={`text-xs lg:text-sm font-semibold rounded-sm bg-primary text-white hover:shadow-xl hover:bg-secondary hover:text-text w-full py-3 px-4`}>Send Now</button>
        </form>
        </>
    )
}

export default ContactForm;