import emailjs from '@emailjs/browser';

// const emailRequest = async (email) => {
//     try {
//         const response = await fetch('https://map-backend-3dot.onrender.com/contact-us/send-contact-form', {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json'
//             },
            
//             body: JSON.stringify(email)
//         });
    
//         if (!response.ok) {
//             throw new Error('Failed to send contact form email');
//           }
//         return await response.json();
    
//     } catch(err){
//         console.log('Error Caught during fetch: ', err);
//         return {error: err.message}
//     }
    
//     }

const emailJs = async(form, templateId) => {

    try{
        const data = await emailjs.sendForm('service_gxik9gk', templateId , form, {
            publicKey: '2Z0R9acnPKMomMtoO',    
        });

        console.log("Success Message: ", data);

    } catch(error){
        console.log("Error Message:", error);
    }
}
    
    
export  {
    emailJs
    };
    