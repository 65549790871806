
import { BiCustomize } from "react-icons/bi";
import { MdOutlineWorkspacePremium } from "react-icons/md";
import { FiPrinter } from "react-icons/fi";
import { TbTruckDelivery } from "react-icons/tb";
import { FaHandHoldingHeart } from "react-icons/fa";
import HomeCarousel from "./Carousel";

const QualityFeatures = () => {
    const features = [
        {
        icon: FiPrinter,
        title: "Museum-Quality Prints",
        description: "Printed on premium 200gsm matte paper with archival-quality inks"
        },
        {
        icon: BiCustomize,
        title: "High Customization",
        description: "Choose from a wide range of customization options to craft your unique style"
        },
        {
        icon: TbTruckDelivery,
        title: "Secure Shipping",
        description: "Carefully packaged and shipped in damage-proof tubes"
        },
        {
        icon: FaHandHoldingHeart,
        title: "Handcrafted Care",
        description: "Each map is carefully checked and handled with attention to detail"
        }
    ];

    const images = [
            {
                key:0,
                url: 'yellow_rome.png',
                description: 'rome-poster-demo',
            },
            {
                key:1,
                url: 'NewYork_Minimal.png',
                description: 'new-york-poster-demo'
            },
            {
                key:2,
                url: 'London_Moonlight.png',
                description: 'london-poster-demo',
            }
            ]

return (
    <>
    <section className="py-16 bg-accent/30">
        <div className="max-w-7xl mx-auto px-4">
        <div className="flex flex-col lg:flex-row gap-12 justify-center items-center">
            {/* Left side - Image */}
            <div className="flex-1 relative">
            <div className="frame aspect-poster-portrait max-w-lg overflow-hidden shadow-2xl">
                <HomeCarousel images={images}/>
                {/* Floating Quality Badge */}
                <div className="absolute -right-4 -bottom-4 bg-primary text-white py-2 px-3 mr-1 rounded-sm shadow-lg">
                <MdOutlineWorkspacePremium className="w-8 h-8 inline" />
                <p className="inline text-sm font-semibold">Premium Quality</p>
                </div>
            </div>
            </div>

            
            <div className="flex-1">
            <h2 className="text-text text-3xl font-bold mb-6">
                Crafted with Quality, 
                <span className="block text-primary">Delivered with Care</span>
            </h2>
            
            <div className="grid sm:grid-cols-2 gap-6">
                {features.map((feature, index) => (
                <div key={index} className="flex gap-4">
                    <div className="p-3 rounded-sm bg-accent/10 text-primary">
                    <feature.icon className="w-6 h-6" />
                    </div>  
                    <div>
                    <h3 className="text-text font-semibold mb-1">{feature.title}</h3>
                    <p className="text-text/70 text-sm">{feature.description}</p>
                    </div>
                </div>
                ))}
            </div>

            </div>
        </div>
        </div>
    </section> 
    </>
    );
};

export default QualityFeatures;