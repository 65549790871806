import { fonts } from "../utils/styles";


const FontCard = ({mapFont, setMapFont}) => {

    const handleFont = (font) => {
        setMapFont(font);
    }

    return (
        <>
                <div className="flex flex-col justify-center items-center w-full">

                <label className="mb-2 text-sm font-semibold w-5/6 text-text">Font</label>
                <div className="flex flex-wrap flex-row gap-3 m-3 justify-evenly">

                    {fonts.map(font => {
                    return(
                    <div key={font} onClick={() => handleFont(font)} className={`${mapFont === font? 'bg-accent border-primary border-2' : 'bg-background border-2 border-accent'} cursor-pointer min-w-[70px] w-1/5 p-1 rounded-md shadow-lg hover:border-2 hover:bg-accent hover:border-secondary hover:text-white`}>
                            <p style={{fontFamily: `${font}`}} className={`${mapFont === font? 'text-white' : 'text-text'} flex justify-center 
                                        text-4xl font-normal`}>A</p>
                        </div>
                    )
                    }
                    )}

                </div>

                </div>
        </>
    )
}

export default FontCard;