import { Link } from "react-router-dom";


const FooterInfo = ()=> {
    return (
        <div className="flex flex-col text-center justify-start h-full">
            <p className="text-xl font-bold text-text mb-2 font-serif">Info</p>
            <Link to='/' className="hover:underline decoration-text mb-4 px-2">Shipping & Returns</Link>
            <Link to='/' className="hover:underline decoration-text mb-4 px-2">Terms & Conditions</Link>
        </div>
    )
}

export default FooterInfo;


