
import Searchbox from "./SideBar/SearchBox";
import Orientation from "./SideBar/Orientation";
import StyleCard from "./SideBar/StyleCard";
import Size from "./SideBar/size";
import Format from './SideBar/format';
import Labels from "./SideBar/Labels";
import LayoutCard from "./SideBar/LayoutCard";
import Order from "./SideBar/Order";
import FontCard from "./SideBar/FontCard";

const SideBar = ({accessToken, mapboxgl, inputValue, setInputValue, mapInstanceRef, orientation, setOrientation, mapStyle, setMapStyle, mapSize, setMapSize, mapFormat, setMapFormat, headline, setHeadline, tagline, setTagline, subtitle, setSubtitle, labels, setLabels, mapLocation, mapLayout, setMapLayout, finalOrder, captureScreenshot, cart, setCart, toggleDrawer, mapFont, setMapFont, setFinalOrder, openScreenshot, currency}) => {

    
    return (
        <div className="w-full bg-accent/20">
            <div className="flex justify-center pt-5">
            <div className="xs:w-full sm:w-4/6 md:w-3/6 lg:w-full m-2 lg:m-0">

                <div className="mb-10">
                    <Searchbox accessToken={accessToken} mapboxgl={mapboxgl} inputValue={inputValue} setInputValue={setInputValue} mapInstanceRef={mapInstanceRef} />
                </div>

                <div className="mb-10">
                    <StyleCard mapStyle={mapStyle} setMapStyle={setMapStyle}/>
                </div>

                <div className="mb-10">
                    <LayoutCard mapLayout={mapLayout} setMapLayout={setMapLayout}/>
                </div>

                <div className="mb-10">
                    <FontCard mapFont={mapFont} setMapFont={setMapFont}/>
                </div>

                <div className="mb-10">
                    <Labels headline={headline} setHeadline={setHeadline} tagline={tagline} setTagline={setTagline} subtitle={subtitle} setSubtitle={setSubtitle} labels={labels} setLabels={setLabels} mapLocation={mapLocation}/>
                </div>

                <div className="mb-10">
                    <Size mapSize={mapSize} setMapSize={setMapSize}/>
                </div>

                <div className="mb-10">
                    <Orientation orientation={orientation} setOrientation={setOrientation}/>
                </div>

                <div className="mb-20">
                    <Format mapFormat={mapFormat} setMapFormat={setMapFormat}/>
                </div>

                <div className="sticky w-full bottom-0">
                    <Order cart={cart} setCart={setCart} finalOrder={finalOrder} captureScreenshot={captureScreenshot} toggleDrawer={toggleDrawer} setFinalOrder={setFinalOrder} openScreenshot={openScreenshot} currency={currency}/>
                </div>

            </div>
            </div>
        </div>
    )
}

export default SideBar;


