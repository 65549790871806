import { Link } from "react-router-dom";
import { FaMap } from "react-icons/fa";
import HomeCarousel from "./Carousel";

const Hero = () => {

    const mockups = [
        {
            key:0,
            url: 'barcelona_maroon_test.jpg',
            description: 'slide-1'
        },
        {
            key:1,
            url: 'poster-mockup.jpg',
            description: 'slide-2'
        }
    ]
    return (
        <>
        <div className="relative w-full min-h-screen bg-accent/30">

            {/* Main Content */}
            <div className="container mx-auto px-4">
            <div className="flex flex-col md:flex-row min-h-screen items-center justify-center gap-12 py-8">
                
                {/* Left Column - Text Content */}
                <div className="flex-1 text-center md:text-left max-w-xl mx-auto md:mx-0">
                <div className="bg-accent/40 backdrop-blur-sm rounded-sm p-8 shadow-lg">
                    <h1 className="text-4xl md:text-6xl font-bold mb-6 text-text">
                    Design Your Own 
                    <span className="block text-primary">Map Poster</span>
                    </h1>
                    
                    <p className="text-lg md:text-xl mb-8 text-text/80">
                    Create personalized map posters of your favorite places in just a few clicks.
                    Turn your meaningful locations into beautiful wall art.
                    </p>

                    <div className="flex flex-col sm:flex-row gap-4 justify-center md:justify-start">
                    <Link
                        to="/poster"
                        className="inline-flex items-center justify-center px-6 py-3 text-lg font-semibold text-white bg-primary hover:bg-secondary hover:text-text transition-colors rounded-sm shadow-lg hover:shadow-xl"
                    >
                        <FaMap className="mr-2 h-5 w-5" />
                        Start Designing
                    </Link>
                    </div>
                </div>
                </div>

                {/* Right Column - Preview */}
                <div className="flex-1 w-full max-w-xl mx-auto md:mx-0">
                <div className="aspect-square rounded-lg overflow-hidden shadow-2xl">
                     <HomeCarousel images={mockups}/> 
                </div>
                </div>

            </div>
            </div>
        </div>
        </>
    )
}

export default Hero;

/**<div className='w-full h-screen flex justify-center items-center bg-cover' style={{backgroundImage: `url(/Hero-Cover.jpg)`}}>
            <div className=" flex flex-col justify-center h-auto text-center bg-accent rounded-md m-6 p-8">
            <h1 className="text-4xl md:text-6xl text-text font-bold mb-4">Design Your Own Map Poster</h1>
            <p className="text-lg text-text md:text-xl mb-8">Create personalized map posters of your favorite places in just a few clicks.</p>
            <Link to="/poster" className={`self-center text-sm lg:text-lg font-semibold rounded-sm bg-primary text-white hover:shadow-xl hover:bg-secondary hover:text-text py-2 px-4`}>
            Start Designing
            </Link>
            </div>

        </div> */