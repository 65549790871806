
import { FaArrowRightLong } from "react-icons/fa6";
import { currencyLogos } from "../utils/styles";

const Order = ({ finalOrder, captureScreenshot, cart, setCart, toggleDrawer, setFinalOrder, openScreenshot, currency}) => {

    const handleOrder = async (order) => {

       const image =  await captureScreenshot();

       const updatedOrder = {
        ...order,
        Image: image,
        };

        setFinalOrder(updatedOrder);

        const newCartItem = {
            ...updatedOrder,
            quantity: 1,
            currency:currency,
            id: new Date().getTime()
        }
        console.log('Cart Item: ', newCartItem);

        setCart((prevCart)=> [...prevCart, newCartItem])
        
        toggleDrawer();

        //openScreenshot();
    }

    return (
        <div className="flex items-center justify-between border-t border-primary h-14 bg-accent/30 text-text font-semibold py-5 px-8">
            <div>Total: {currencyLogos[currency]}{finalOrder.Price}</div>
            <div > 
            <button onClick={() => handleOrder(finalOrder)} className={`text-xs lg:text-sm font-semibold rounded-sm hover:shadow-xl bg-accent hover:bg-primary hover:text-white w-full py-2 px-6`}><div className="flex items-center">Add to Cart<div className="pl-2 pt-0.5"><FaArrowRightLong/></div></div></button>
            </div>
        </div>
    );
};

export default Order;