import NavBar from "./NavBar/NavBar";
import Footer from "./Footer/Footer";
import { Outlet } from 'react-router-dom';
import { useState } from "react";

function App() {

  const [ cart, setCart ] = useState([]);
  
  const [Open, setIsOpen] = useState(false);
  const [ rates, setRates ] = useState({}) 
  const [ currency, setCurrency ] = useState('CAD');

  const toggleDrawer = () => {
    setIsOpen(!Open);
  };


  return (
    <div className="flex flex-col min-h-screen">
      <NavBar cart={cart} setCart={setCart} Open={Open} toggleDrawer={toggleDrawer} currency={currency} rates={rates}/>
      <div className="flex-grow">
        <Outlet context={[cart, setCart, toggleDrawer, rates, currency]} />
      </div>
      <Footer setRates={setRates} currency={currency} setCurrency={setCurrency}/>
    </div>
  );
}

export default App;
