import { FiMapPin, FiLayout, FiType, FiMaximize, FiDownload } from 'react-icons/fi';
import { FaRegCompass } from 'react-icons/fa';
import { IoColorPaletteOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';
import { RxFontFamily } from "react-icons/rx";

  
const customizationOptions = [
    {
    icon: FiMapPin,
    title: "Search Location",
    },
    {
    icon: IoColorPaletteOutline,
    title: "Pick Style",
    },
    {
    icon: FiLayout,
    title: "Select Layout",
    },
    {
    icon: FiType,
    title: "Add Labels",
    },
    {
    icon: FiMaximize,
    title: "Select Size",
    },
    {
    icon: FaRegCompass,
    title: "Pick Orientation",
    },
    {
    icon: FiDownload,
    title: "Choose Format",
    }, 
    {
    icon:RxFontFamily,
    title: "Font Style"
    }
    ];

export default function DesignOptions() {
    return (
    <section className="py-16 px-4 bg-accent/30">
        <div className="max-w-7xl mx-auto">
            {/* Section Header */}
            <div className="text-center mb-16">
            <h2 className="text-primary text-4xl font-bold mb-4">Customize Your Perfect Map</h2>
            
            </div>
            <div className='flex flex-wrap w-full bg-accent/70 mx-auto rounded-sm pb-10'>
                <div className='p-4 h-full w-full'>
                <p className="text-text w-full text-xl text-center">
                    Make it truly yours with our wide range of customization options
                </p>
                </div>
                <div className="m-2 flex flex-wrap justify-center gap-2 md:gap-6 h-full w-full">
                    {customizationOptions.map((option, index) => (
                        <div key={index} className='flex flex-wrap justify-center'>
                            <div className='flex items-center p-2 md:p-3 rounded-md bg-secondary min-w-40 md:min-w-48'>
                                <div className='p-2 rounded-lg bg-accent text-primary'>
                                <option.icon className='h-4 w-4'/>
                                </div>
                                <p className='text-sm md:text-base p-2 flex-1 text-center'>{option.title}</p>
                            </div> 
                        </div>
                    ))}
                </div>
            </div>

            
            {/* Bottom CTA */}
            <div className="text-center mt-16">
            <p className="text-text mb-6 text-lg">
                Ready to create your custom map poster?
            </p>
            <button className="bg-primary text-white px-8 py-3 rounded-sm font-semibold hover:bg-secondary hover:text-text transition-colors duration-300 shadow-sm hover:shadow-md">
                <Link to='/poster'>Start Designing Now</Link>
            </button>
            </div>
        </div>
    </section>
    );
}