import { useClickAway } from "react-use";
import { useRef, useState } from "react";
import { Squash as Hamburger } from "hamburger-react";
import { AnimatePresence, motion } from "framer-motion";
import { BiShoppingBag } from "react-icons/bi";
import { Link } from "react-router-dom";
import DrawerBar from "../CheckoutDrawer/Drawer";

const NavMobile = ({Open, toggleDrawer, cart, setCart, currency, rates}) => {
    
    const [ isOpen, setIsOpen ] = useState(false);
    const ref = useRef(null);

    useClickAway(ref, ()=> setIsOpen(false));

    return (
        <nav className="block md:hidden bg-accent/30 text-text py-4 px-6">
            <div className="flex items-center justify-between">
                
                <Link to='/'>
                <div className="flex"> 
                    <img src='/logo_design.png' alt='company_logo' style={{width:'30px', height:'30px'}}/>
                    <p className="px-2 text-lg text-[#333333] font-semibold">globe & map</p>
                </div>
                </Link>
            
                <div className="cursor-pointer flex items-center">

                <div onClick={toggleDrawer} className="flex items-center pr-3">
                <div className="pr-2 font-semibold">Cart</div>
                <div className="relative">
                <BiShoppingBag/>
                <div className={`${cart.length? 'flex items-center justify-center': 'hidden'} absolute top-[-15px] right-[-15px] rounded-full bg-primary w-6 h-6`}>{cart.length}</div>
                </div>
                </div>

                <Hamburger toggled={isOpen} size={20} toggle={setIsOpen}/>
                <DrawerBar Open={Open} toggleDrawer={toggleDrawer} cart={cart} setCart={setCart} currency={currency} rates={rates}/>
                <AnimatePresence>
                {isOpen && (
                    <motion.div 
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                    className="z-10 absolute left-0 shadow-4xl right-0 top-[5rem] p-5 bg-background"
                    >
                        <div className=" mx-auto flex flex-col gap-2">

                        <motion.div
                        initial={{ scale: 0, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{
                          type: "spring",
                          stiffness: 200,
                          damping: 20,
                          delay: 0.1 + 0 / 10,
                        }}
                        className="rounded-sm bg-accent p-2 text-center"
                        >
                        <Link to='/'>
                        Home
                        </Link>
                        </motion.div>

                        <motion.div
                        initial={{ scale: 0, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{
                          type: "spring",
                          stiffness: 200,
                          damping: 20,
                          delay: 0.1 + 1 / 10,
                        }}
                        className="rounded-sm bg-accent p-2 text-center"
                        >
                        <Link to='poster'> 
                        Build Your Design
                        </Link>
                        </motion.div>

                        <motion.div
                        initial={{ scale: 0, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{
                          type: "spring",
                          stiffness: 200,
                          damping: 20,
                          delay: 0.1 + 2 / 10,
                        }}
                        className="rounded-sm bg-accent p-2 text-center"
                        >
                        <Link to='/about-us'>
                        About Us
                        </Link>
                            </motion.div>
                            
                        <motion.div
                        initial={{ scale: 0, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{
                          type: "spring",
                          stiffness: 200,
                          damping: 20,
                          delay: 0.1 + 3 / 10,
                        }}
                        className="rounded-sm bg-accent p-2 text-center"
                        >
                        <Link to='/contact-us'>
                        Contact
                        </Link>
                            </motion.div>

                        </div>
                    </motion.div>
                )}
                </AnimatePresence>
                <DrawerBar />
                </div>
            </div>
        </nav>
        // <div className="md:hidden">
        //     
        // </div>
    )
}

export default NavMobile;