import { Link } from "react-router-dom";
import { BiShoppingBag } from "react-icons/bi";
import { BiSolidShoppingBag } from "react-icons/bi";
import DrawerBar from "../CheckoutDrawer/Drawer";

const NavDesktop = ({Open, toggleDrawer, cart, setCart, currency, rates}) => {


    return (
        <>
        <nav className="hidden md:block bg-accent/30 text-text py-4 px-4">
            <div className="flex items-center justify-between">
                <div className="flex space-x-28">
                <Link to='/'>
                <div className="flex"> 
                    <img src='/logo_design.png' alt='company_logo' style={{width:'30px', height:'30px'}}/>
                    <p className="px-2 text-lg text-[#333333] font-semibold">globe & map</p>
                </div>
                </Link>
                <div className="font-normal space-x-6">
                    <Link to='/' className="hover:underline decoration-text">Home</Link>
                    <Link to='poster' className="hover:underline decoration-text">Build Your Design</Link>
                    <Link to='/about-us' className="hover:underline decoration-text">About Us</Link>
                    <Link to='/contact-us'  className="hover:underline decoration-text">Contact</Link>
                </div>
                </div>
                <div onClick={toggleDrawer} className="cursor-pointer flex items-center h-full py-2 px-6 rounded-md">
                <div className="pr-1 font-semibold">Cart</div>
                <div className="relative">
                {cart.length?<BiSolidShoppingBag className="text-text"/>:<BiShoppingBag className="text-text"/>}
                <div className={`${cart.length? 'flex items-center justify-center': 'hidden'} absolute top-[-15px] right-[-15px] rounded-full bg-primary text-white w-6 h-6`}>{cart.length}</div>
                </div>
                <DrawerBar Open={Open} toggleDrawer={toggleDrawer} cart={cart} setCart={setCart} currency={currency} rates={rates}/>
                </div>
            </div>
            
        </nav>
        
        </>
    )
}

export default NavDesktop;