// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { 
    getFirestore,
    collection,
    addDoc,
 } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDwHmU0bcg1wT6I5Jbr--bBH-QhO9SXrUU",
  authDomain: "map-poster-8209e.firebaseapp.com",
  projectId: "map-poster-8209e",
  storageBucket: "map-poster-8209e.appspot.com",
  messagingSenderId: "366833941454",
  appId: "1:366833941454:web:9bd7726ced1057df5d899f",
  measurementId: "G-BM66LZBXY2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);

const db = getFirestore(app);

const addDocToFireStore = async (cart) => {
    try {
        const docRef = await addDoc(collection(db,'orders'), {
            createdAt: new Date().toISOString(),
            cart: cart,
        });
        console.log("Document written with ID: ", docRef.id);
        return docRef.id;
    } catch(e) {
        console.log("Error adding Document to Firestore: ", e);
    }
}

export default addDocToFireStore;