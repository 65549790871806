import { IoIosLock } from "react-icons/io";
import { FaStripe } from "react-icons/fa";



const PaymentOption = () => {
    return (
        <>
        <div className="text-text border-b-2 border-text py-2 m-2 flex items-center justify-center">
        <div>
        <IoIosLock className='inline w-6 h-6'/>
        <p className="inline px-1 font-bold text-sm lg:text-base">Guaranteed safe & secure checkout</p>
        </div>
        
        </div>
        <div className='flex gap-0 xl:gap-2 justify-center items-center flex-col xl:flex-row'>
            <div className="px-2 mx-auto">
            <p className="inline text-sm text-text">Powered by <FaStripe className="text-[#5433FF] inline w-10 h-10"/></p>
            </div>
            <div className='py-2 m-2 flex gap-4 justify-center'>
            <img src='1.png' alt='visa-logo' width='40px' height='20px'/>
            <img src='2.png' alt='visa-logo' width='40px' height='20px'/>
            <img src='22.png' alt='visa-logo' width='40px' height='20px'/>
            <img src='14.png' alt='visa-logo' width='40px' height='20px'/>
            </div>
        </div>
        </>
    )
}

export default PaymentOption;