import { useEffect} from "react";

const CurrencyOptions = ({ setRates, currency, setCurrency}) => {

    

    const handleChange = (e) => {
        setCurrency(e.target.value);
    }

    useEffect(()=> {
        const getRates = async () => {
            const response = await fetch('https://map-backend-3dot.onrender.com/exchangeRates?base_currency=CAD&currencies=USD,EUR,GBP,JPY,CAD')
            const rates = await response.json()
            setRates(rates.data);
        }

        getRates();

    },[currency, setRates])

    return (
        <>
        <div className="min-w-24 min-h-6">
            <div className="w-full h-full">
            <select value={currency} onChange={handleChange} className='h-full w-full p-2 text-sm bg-background rounded-sm border border-accent mb-3 text-text'>
                <option value='CAD'>Canadian Dollar (CAD $)</option>
                <option value='USD'>US Dollar (USD $)</option>
                <option value='GBP'>Pound Stirling (£)</option>
                <option value='EUR'>Euro (€)</option>
                <option value='JPY'>Japanese Yen (¥)</option>
            </select>
            </div>
        </div>
        </>
    )
}

export default CurrencyOptions;