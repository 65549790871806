import { useRef } from "react";
import { emailJs } from "../Utils/NodeMailer/nodeMailer";
import { FaInstagram } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa";

const MailingList = ({mailingList, setMailingList}) =>{

    const form = useRef();

    const handleChange = (e) =>{
        setMailingList(e.target.value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(mailingList) {
            await emailJs(form.current, 'template_s143nab');
        }
    }

    return (
        <div ref={form} className="flex flex-col items-center justify-start h-full">
            <form onSubmit={handleSubmit} className='rounded-sm text-text  mx-auto px-2 mb-2'>
            <label><h1 className='pb-2 text-text font-normal text-base min-w-[192px]'>Join our list to get the latest offers and discounts</h1></label>
            <div className="flex flex-col lg:flex-row gap-3">
            <input value={mailingList} onChange={handleChange} type='email' name='email' placeholder="Email" required className="block w-full p-2 text-sm bg-background rounded-sm border border-accent mb-3"/>
            <button type='submit' className={`text-xs lg:text-sm font-semibold rounded-sm hover:shadow-xl bg-primary text-white py-2 px-4 mb-3`}>Submit</button>
            </div>
            </form>
            <div className="flex w-full mb-2 gap-3 mx-auto max-w-[365px] items-center px-2 ">
                <a href='https://www.instagram.com/globenmap/' target='_blank' rel='noopener noreferrer'>
                    <div className="border-2 rounded-full border-text hover:bg-text hover:text-white cursor-pointer">
                        <FaInstagram className="h-6 w-6 m-1"/>
                    </div>
                </a>
                <div className="border-2 rounded-full border-text hover:bg-text hover:text-white cursor-pointer">
                    <FaTiktok className="h-6 w-6 m-1"/>
                </div>
                <div className="border-2 rounded-full border-text hover:bg-text hover:text-white cursor-pointer">
                    <FaFacebook className="h-6 w-6 m-1"/>
                </div>
            </div>
        </div>
    )

}

export default MailingList;