


const ShippingAndReturns = () => {
    return (
        <>
        <div className="flex flex-col items-center bg-accent/30 p-6 min-h-screen">
            <div className="w-full h-48 bg-cover rounded-sm mb-10">
                <div className="flex items-center justify-center w-full h-full">
                    <h1 className="text-3xl text-text font-bold">Shipping & Returns Policy</h1>
                </div>
            </div>
            <div className="flex flex-col md:flex-row items-center justify-center w-full gap-6">
                Test
            </div>
        </div>
        </>
    )
}

export default ShippingAndReturns;