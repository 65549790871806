


const stripeRequest = async (requestBody) => {
    const productionURL = 'https://map-backend-3dot.onrender.com/create-checkout-session';
    const devURL = 'http://localhost:4242/create-checkout-session';
try {
    const data = await fetch(productionURL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        
        body: JSON.stringify(requestBody)
    });

    if (!data.ok) {
        throw new Error('Failed to create checkout session');
      }
  
      const { url } = await data.json();
      window.location.href = url;


} catch(err){
    console.log('Error Caught during fetch: ', err);
    return null
}

}


export default stripeRequest;
