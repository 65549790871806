import { Link } from "react-router-dom";
import FooterInfo from "./FooterInfo";
import FooterMenu from "./FooterMenu";
import MailingList from "./MalingList";
import PaymentOption from "./PaymentOptions";
import CurrencyOptions from "./CurrencyOptions";
import { useState } from "react";

const Footer = ({ setRates, currency, setCurrency }) =>{

    const [ mailingList, setMailingList ] = useState('');


    return (
    <div className='flex flex-col'>
        <div className="flex flex-col md:flex-row w-full bg-accent/30 h-full md:h-48 pt-8 md:min-h-[250px] lg:min-h-[225px]">
        <div className="flex-1">
            <Link to='/'>
            <div className="flex w-full h-full min-w-[207px] justify-center items-start mb-4 px-2">
            <img src='/logo_design.png' alt='company_logo' style={{width:'40px', height:'40px'}}/>
            <p className="px-2 py-1 text-2xl text-[#333333] font-semibold">globe & map</p>
            </div>
            </Link>
        </div>
        <div className="flex-1">
            <FooterMenu/>
        </div>
        <div className="flex-1">
            <FooterInfo/>
        </div>
        <div className="flex-1">
            <MailingList mailingList={mailingList} setMailingList={setMailingList}/>
        </div>
        </div>
        <div className="flex bg-accent/30 w-full flex-col md:flex-row justify-center md:justify-between items-center">
            <div className="flex-1">
                <div className='w-full md:w-11/12 md:max-w-[200px] lg:w-4/6 m-2'>
                    <CurrencyOptions setRates={setRates} currency={currency} setCurrency={setCurrency}/>
                </div>
            </div>
            <div className="flex-1 order-2 md:order-none">
                <div className="m-2">
                    <p className="text-sm text-text text-center">
                        &copy; {new Date().getFullYear()} <span className="font-semibold">globe & map</span>. All rights reserved.
                    </p>
                </div>
            </div>
            <div className="flex flex-1 justify-end">
                <div className="w-full md:w-10/12 m-2">
                    <PaymentOption/>
                </div>
            </div>
        </div>
    </div>
    )
}

export default Footer;