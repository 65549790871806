import React, { useState, useEffect, useRef } from 'react';
import { reverseGeocode, processGeocodeResponse } from './utils/mapboxApi';
import mapboxgl from 'mapbox-gl';
import html2canvas from 'html2canvas';
import Map from "./Map";
import SideBar from './SideBar';
import { useOutletContext } from 'react-router-dom';

const Container = ()=> {
    const accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
    const mapsToken = process.env.REACT_APP_GOOGLE_API;
    
    const mapContainerRef = useRef();
    const mapInstanceRef = useRef();

    const [ cart, setCart, toggleDrawer, rates, currency] = useOutletContext();
    const [mapLoaded, setMapLoaded] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [mapData, setMapData] = useState({
        center: null,
        zoom: null,
        bearing: null,
        pitch: null,
        bounds: null,
        type: null
    })
    const [mapStyle, setMapStyle] = useState('mapbox://styles/touseefyounas/clv4ai31s02b401nu34ut83gg');
    const [mapLayout, setMapLayout ] = useState('default');
    const [orientation, setOrientation ] = useState('portrait');
    const [mapLocation, setMapLocation ] = useState(null);
    const [mapSize, setMapSize ] = useState(0);
    const [mapFormat, setMapFormat ] = useState('Print');
    const [mapFont, setMapFont ] = useState('Roboto');
    const [headline, setHeadline ] = useState('');
    const [tagline, setTagline ] = useState('');
    const [subtitle, setSubtitle ] = useState('');
    const [labels, setLabels ] = useState(true);
    const[finalOrder, setFinalOrder ] = useState({
        Style: mapStyle,
        Layout: mapLayout,
        Orientation: orientation,
        Size: mapSize,
        'Map Data': mapData,
        Headline: headline,
        Tagline: tagline,
        Subtitle: subtitle,
        Labels: labels,
        Format: mapFormat,
        Price: null
    });
    const [scale, setScale ] = useState(0.73);
    const [ size, setSize ] = useState({
        width: null,
        height: null,
        minScale: null,
        maxScale: null,
    });
    

    useEffect(() => {
        mapboxgl.accessToken = accessToken;
    
        mapInstanceRef.current = new mapboxgl.Map({
          container: mapContainerRef.current, // container ID
          center: [-74.0060, 40.7128], // starting position [lng, lat]
          zoom: 11, // starting zoom
          style: mapStyle,
          preserveDrawingBuffer: false,
          renderWorldCopies: false,
          
        });
    
        mapInstanceRef.current.on("load", () => {
            const map = mapInstanceRef.current;
            const center = map.getCenter();
            const zoom = map.getZoom();
            const bearing = map.getBearing();
            const pitch = map.getPitch();
            const bounds = map.getBounds();

            setMapData({
                center: {
                    lat: center.lat,
                    lng: center.lng,
                },
                zoom: zoom,
                bearing: bearing,
                pitch: pitch,
                bounds: {
                    _ne:{
                        lat: bounds._ne.lat,
                        lng: bounds._ne.lng,
                    },
                    _sw: {
                        lat: bounds._sw.lat,
                        lng: bounds._sw.lng
                    }
                },
                type: 'Load'
            })  
          setMapLoaded(true);
    
        });

        mapInstanceRef.current.addControl(new mapboxgl.NavigationControl());
     

      }, []);
    
    useEffect(() => {
        // Add the moveend event listener
        mapInstanceRef.current.on('moveend', () => {
            const map = mapInstanceRef.current;
            const center = map.getCenter();
            const zoom = map.getZoom();
            const bearing = map.getBearing();
            const pitch = map.getPitch();
            const bounds = map.getBounds();

            setMapData({
                center: {
                    lat: center.lat,
                    lng: center.lng,
                },
                zoom: zoom,
                bearing: bearing,
                pitch: pitch,
                bounds: {
                    _ne:{
                        lat: bounds._ne.lat,
                        lng: bounds._ne.lng,
                    },
                    _sw: {
                        lat: bounds._sw.lat,
                        lng: bounds._sw.lng
                    }
                },
                type: 'Move End'
            })
        });
        

    }, [])


    useEffect( ()=> {

        const calculatePrice = () => {
            let basePrice = 9.99 * rates[currency];
            if (mapFormat=== 'Print') {
                switch (mapSize) {
                    case 0:
                        basePrice = 27.50 * rates[currency];
                        break;
                    case 1:
                        basePrice = 32.50 * rates[currency];
                        break;
                    case 2:
                        basePrice = 37.50 * rates[currency];
                        break;
                    case 3:
                        basePrice = 48.50 * rates[currency];
                        break;
                    default:
                        break;
                }  
            } 
            return parseFloat(basePrice.toFixed(2));
        }

        setFinalOrder({
            Style: mapStyle,
            Layout: mapLayout,
            Orientation: orientation,
            Size: mapSize,
            'Map Data': mapData,
            Headline: headline,
            Tagline: tagline,
            Subtitle: subtitle,
            Labels: labels,
            Format: mapFormat,
            Font: mapFont,
            Price: calculatePrice(),
            timestamp: new Date().toISOString(),
        })
    }, [mapStyle, mapLayout, orientation, mapSize, mapData, headline, tagline, subtitle, labels, mapFormat, mapFont, currency, rates]);


    useEffect(() => {
        if (mapLoaded) {
          // Update the map style without changing the view state
          mapInstanceRef.current.setStyle(mapStyle);
        }
      }, [mapStyle, mapLoaded]);

    useEffect(()=> {
        if (mapInstanceRef.current){
            mapInstanceRef.current.resize();
        }
    }, [orientation, mapSize, size])

    useEffect( () => {
        
        if (mapData.center) {
            reverseGeocode(mapData.center.lng, mapData.center.lat, accessToken, mapsToken)
            .then(geocodeData => processGeocodeResponse(geocodeData, mapData))
            .then(locationData => setMapLocation(locationData))
            .catch(err => console.log('Error:', err));
        }
    }, [mapData])

    const captureScreenshot = async () => {
        const mapContainer = document.getElementById('container')
        if (!mapContainer) {
            console.error('Map container not found');
            return;
        }
        try {
            
            const canvas = await html2canvas(mapContainer, {useCORS: true})
            
            const image = canvas.toDataURL('image/png')

            return image;
            
        } catch (err) {
            console.log(err)
        }
        }

    const openScreenshot =  () => {
        const map = mapInstanceRef.current;
        const canvas = map.getCanvas();
        const dataUrl = canvas.toDataURL('image/png');
        const newTab = window.open();
        newTab.document.write(`<img src="${dataUrl}" alt="captured-map-image"/>`)
    }

    return (
    <div  className="grid grid-rows-auto lg:grid-rows-12 grid-cols-1 lg:grid-cols-12">
        <div className="row-start-1 lg:row-span-12 col-start-1 lg:col-start-4 md:col-span-9 bg-background ">
            <Map mapContainerRef={mapContainerRef}
            orientation={orientation} 
            mapData={mapData} 
            mapLocation={mapLocation} 
            headline={headline}
            tagline={tagline}
            subtitle={subtitle}
            labels={labels}
            mapLayout={mapLayout}
            mapSize={mapSize}
            scale={scale}
            setScale={setScale}
            size={size}
            setSize={setSize}
            mapStyle={mapStyle}
            mapFont={mapFont}
            />
        </div>
        <div className="row-start-2 lg:row-span-12 lg:row-start-1 lg:col-start-1 lg:col-span-3 bg-white lg:h-screen lg:overflow-y-auto">
            
            <SideBar 
            accessToken={accessToken} 
            mapboxgl={mapboxgl} 
            inputValue={inputValue} 
            setInputValue={setInputValue} 
            mapInstanceRef={mapInstanceRef} 
            orientation={orientation} 
            setOrientation={setOrientation}
            mapStyle={mapStyle} 
            setMapStyle={setMapStyle}
            mapSize={mapSize} 
            setMapSize={setMapSize}
            mapFormat={mapFormat} 
            setMapFormat={setMapFormat}
            headline={headline}
            setHeadline={setHeadline}
            tagline={tagline}
            setTagline={setTagline}
            subtitle={subtitle}
            setSubtitle={setSubtitle}
            labels={labels}
            setLabels={setLabels}
            mapLocation={mapLocation}
            mapLayout={mapLayout}
            setMapLayout={setMapLayout}
            finalOrder={finalOrder}
            setFinalOrder={setFinalOrder}
            captureScreenshot={captureScreenshot}
            cart={cart} 
            setCart={setCart}
            toggleDrawer={toggleDrawer}
            mapFont={mapFont}
            setMapFont={setMapFont}
            openScreenshot={openScreenshot}
            currency={currency}
            />
        </div>
    </div>
    )
    };

export default Container;

//className="grid grid-rows-auto lg:grid-rows-12 grid-cols-1 lg:grid-cols-12 h-screen"
//className="row-start-1 lg:row-span-12 col-start-1 lg:col-start-4 md:col-span-9 bg-background"
//className="row-start-2 lg:row-span-12 lg:row-start-1 lg:col-start-1 lg:col-span-3 bg-white lg:overflow-y-auto"