import { useState } from "react";
import ContactForm from "./ContactForm";
import ContactInfo from "./ContactInfo";
import { FaCircle, FaCircleCheck } from "react-icons/fa6";

const ContactPage = () => {

    const [submitted, setSubmitted ] = useState(false);

    const [ contactForm, setContactForm ] = useState(
        {
            name: '',
            email: '',
            phone: '',
            message: '',
        }
    );

    return(
        <>
        <div className="flex flex-col items-center justify-center bg-accent/30 p-6">
        <div className="w-full h-48 bg-cover rounded-sm mb-10">
                <div className="flex items-center justify-center w-full h-full">
                    <h1 className="text-3xl text-text font-bold">Get in Touch</h1>
                </div>
            </div>
            <div className="flex flex-col md:flex-row items-center justify-center w-full gap-6">
                <div className="flex justify-center items-center w-full h-80 mb-6 bg-accent">
                <ContactInfo/>
                </div>
            <div className="flex items-center justify-center w-full h-screen mb-6">
                <div className="w-full md:w-11/12 lg:w-4/5 bg-accent">
                { submitted ?(
                    <div>
                    <FaCircleCheck size={32} color="#6B7F5E"/>
                    <p className="text-text">Thank you for contacting us!</p>
                    </div>
                )
                :
                   ( 
                   <ContactForm contactForm={contactForm} setContactForm={setContactForm} setSubmitted={setSubmitted}/>
                )}
                </div>
            </div>
            </div>
        </div>
        </>
    )
}

export default ContactPage;


