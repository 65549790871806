const createGradient = (baseColor) => {
    // Convert hex to rgba for smoother gradients
    const hexToRgba = (hex, alpha) => {
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    };

    return `linear-gradient(
        to bottom,
        ${hexToRgba(baseColor, 0)} 0%,
        ${hexToRgba(baseColor, 0.4)} 20%,
        ${hexToRgba(baseColor, 0.7)} 40%,
        ${hexToRgba(baseColor, 0.85)} 60%,
        ${hexToRgba(baseColor, 0.95)} 80%,
        ${hexToRgba(baseColor, 1)} 100%
    )`;
};


const styles = [
    {
        style: 1,
        styleName: 'Minimal',
        styleUrl: 'mapbox://styles/touseefyounas/clv4ai31s02b401nu34ut83gg',
        styleImage: '/Minimal.png',
        colors: {
            primary: '#606060',
            secondary: '#d0d0d0',
            }
    },
    {
        style: 2,
        styleName: 'Nautica',
        styleUrl: 'mapbox://styles/touseefyounas/clv4bf0jk02bk01nugg46b6hx',
        styleImage: '/Nautica.png',
        colors: {
            primary: '#a6b37a',
            secondary: '#f5f5dc',
        }
    },
    {
        style: 3,
        styleName: 'Neon',
        styleUrl: 'mapbox://styles/touseefyounas/clv4bn8lr02a401pkfamv245h',
        styleImage: '/Neon.png',
        colors: {
            primary: '#ff4c05',
            secondary: '#2f4f4f'
        }
    },
    {
        style: 4,
        styleName: 'Vintage',
        styleUrl: 'mapbox://styles/touseefyounas/clv4a4fzf02b601pe6aw3flsc',
        styleImage: '/Vintage.png',
        colors: {
            primary: '#b3b17a',
            secondary: '#f5f1e6',
        }
    },
    {
        style: 5,
        styleName: 'Solaris',
        styleUrl: 'mapbox://styles/touseefyounas/clv43ol39026l01pk6zvh9gbf',
        styleImage: '/yellow.png',
        colors: {
            primary: '#f5a201',
            secondary:'#0b1e33'
        }
    },
    {
        style: 6,
        styleName: 'Ember',
        styleUrl: 'mapbox://styles/touseefyounas/clv44b8w4028i01pe8std3c8s',
        styleImage: '/broon.png',
        colors: {
            primary: '#980101',
            secondary: '#fbeed7'
        }
    },
    {
        style: 7,
        styleName: 'Crimson',
        styleUrl: 'mapbox://styles/touseefyounas/clzbb6kfb00k201qmac8mddet',
        styleImage: '/Maroon.png',
        colors: {
            primary: '#550000',
            secondary: '#fbeed7',
        }
    },
    {
        style: 8,
        styleName: 'Moonlight',
        styleUrl: 'mapbox://styles/touseefyounas/clualwfm2007x01qfch2ga9ah',
        styleImage: '/Night.png',
        colors: {
            primary:'#5a6c6d',
            secondary: '#292929',
        }

    },
    
]

const sizes = [
    {
        size: 0,
        sizeName: '12" x 16"',
        width: 1152,
        height: 1536,
        minScale: 0.12,
        maxScale: 1.6,
        priceId: 'price_1QRN9aRruZcEpy4ul2MaUsiO',

    },
    {
        size: 1,
        sizeName: '16" x 20"',
        width: 1536,
        height: 1920,
        minScale: 0.12,
        maxScale: 1.2,
        priceId: 'price_1QRNAmRruZcEpy4uM2wOGG9h',
    },
    {
        size: 2,
        sizeName: '18" x 24"',
        width: 1728,
        height: 2304,
        minScale: 0.18,
        // minScale: 0.10,
        maxScale: 1.00,
        priceId: 'price_1QRNBcRruZcEpy4uUrSPjIk9',
    },
    {
        size: 3,
        sizeName: '24" x 36"',
        width: 2304,
        height: 3456,
        minScale: 0.07,
        maxScale: 0.73,
        priceId: 'price_1PoQpmRruZcEpy4uobgNnsOt',
    },
    
]

const fonts = [
    'Roboto',
    'Roboto Mono',
    'Dancing Script',
    'Monoton',
]

const currencyLogos = {
    'CAD': 'CAD $',
    'USD': 'US $',
    'GBP': '£',
    'JPY': '¥',
    'EUR': '€',
}

const layouts = [
    {
        id: 'default',
        name: 'default',
        component: ({headline, tagline, subtitle, mapLocation, labels, textSize, colors, mapFont}) => {
            return (
                <div style={{
                    fontFamily: mapFont, 
                    color: colors.text, 
                    background: createGradient(colors.background),
                    ...(mapFont === 'Monoton' && {
                        letterSpacing: '4px', // Increase letter spacing to make it uniform
                        lineHeight: '1.2', // Adjust line-height for spacing between lines
                        WebkitFontSmoothing: 'antialiased', // Font smoothing for better rendering
                        MozOsxFontSmoothing: 'grayscale', // Font smoothing for better rendering
                      }),
                }}
                className={`${labels ? 'flex' : 'hidden'} absolute bottom-0 left-0 right-0 flex-col justify-end items-center 
                            w-full min-h-[20%]
                            pb-12 px-5 z-10`}>
                        <div className={`w-full flex flex-col gap-2 items-center`}>
                        <div className={`text-center ${textSize.headline} overflow-hidden leading-tight ${mapFont === 'Monoton'? 'font-normal':'font-bold'}`}>
                            {/* {headline ?? mapLocation?.city ?? 'New York'} */}
                            New York
                        </div>
                        
                        <div className="text-center overflow-hidden">
                            <span className={`${textSize.tagline} leading-tight`}>
                                {/* {tagline ?? mapLocation?.country ?? 'United States'} */}
                                United States
                            </span>
                        </div>
                        
                        <div className={`text-center ${textSize.subtitle} font-light leading-tight`}>
                            {/* {subtitle ?? (mapLocation ? `${mapLocation.center.lat} / ${mapLocation.center.long}` : '40.7128 °N / 74.0060 °W')} */}
                            40.7128 °N / 74.0060 °W
                        </div>
                    </div>
                </div>   
            )
        },
        layoutImage: '/layout4.png'
    },
    {
        id: 'mono',
        name: 'mono',
        component: ({headline, tagline, subtitle, mapLocation, labels, textSize, colors, mapFont}) => {
        return (
        <div style={{
            fontFamily: mapFont, 
            color: colors.text, 
            backgroundColor:colors.background, 
            borderColor: colors.text,
            ...(mapFont === 'Monoton' && {
                letterSpacing: '4px', // Increase letter spacing to make it uniform
                lineHeight: '1.2', // Adjust line-height for spacing between lines
                WebkitFontSmoothing: 'antialiased', // Font smoothing for better rendering
                MozOsxFontSmoothing: 'grayscale', // Font smoothing for better rendering
              }),
         }} className={`${labels ? 'block' : 'hidden'} border-t-4 absolute bottom-0 right-0 left-0 z-10 w-full px-6 pb-2 `}>
            <div className={`block text-end ${textSize.headline}  overflow-hidden ${mapFont === 'Monoton'? 'font-normal':'font-bold'}`}>
                {headline? headline : mapLocation?mapLocation.city: 'New York'}
                </div>
            <div className="block text-end tracking-wider overflow-hidden">
                <span className={`block-inline relative  ${textSize.tagline}`}>{tagline? tagline : mapLocation? mapLocation.country: 'United States'}</span>
            </div>
            <div className={`${textSize.subtitle} text-end font-light`}>{subtitle? subtitle : mapLocation?`${mapLocation.center.lat} / ${mapLocation.center.long}`: '40.7128 °N / 74.0060 °W'}</div>
        </div>
        )
        },
        layoutImage: '/layout3.png'
    },
    {
        id: 'top',
        name: 'top',
        component: ({headline, tagline, subtitle, mapLocation, labels, textSize, colors, mapFont}) => {
            return (
            <div style={{
                fontFamily: mapFont, 
                color: colors.text, 
                backgroundColor:colors.background, 
                borderColor:colors.text,
                ...(mapFont === 'Monoton' && {
                    letterSpacing: '4px', // Increase letter spacing to make it uniform
                    lineHeight: '1.2', // Adjust line-height for spacing between lines
                    WebkitFontSmoothing: 'antialiased', // Font smoothing for better rendering
                    MozOsxFontSmoothing: 'grayscale', // Font smoothing for better rendering
                  }),
             }} className={`flex items-center justify-between ${labels ? 'block' : 'hidden'} absolute bottom-0 right-0 left-0 border-t-4 z-10 w-full px-6 pb-2`}>
                
                <div className={`${textSize.headline}  overflow-hidden ${mapFont === 'Monoton'? 'font-normal':'font-bold'}`}>
                    {headline? headline : mapLocation?mapLocation.city: 'New York'}
                    </div>
                <div>
                <div className={`tracking-wider text-right ${textSize.tagline}`}>{tagline? tagline : mapLocation? mapLocation.country: 'United States'}</div>
                
                <div className={`${textSize.subtitle}`}>{subtitle? subtitle : mapLocation?`${mapLocation.center.lat} / ${mapLocation.center.long}`: '40.7128 °N / 74.0060 °W'}</div>
                </div>
            </div>
            )
            },
        layoutImage: '/layout2.png',
    },
    {
        id: 'dynamic',
        name: 'dynamic',
        component: ({headline, tagline, subtitle, mapLocation, labels, textSize, colors, mapFont}) => {
            return (
            <div style={{ transform: 'translate(-50%)', 
                fontFamily: mapFont,
                color: colors.text, 
                backgroundColor:colors.background, 
                ...(mapFont === 'Monoton' && {
                    letterSpacing: '4px', // Increase letter spacing to make it uniform
                    lineHeight: '1.2', // Adjust line-height for spacing between lines
                    WebkitFontSmoothing: 'antialiased', // Font smoothing for better rendering
                    MozOsxFontSmoothing: 'grayscale', // Font smoothing for better rendering
                  }),
            }} 
                className={`${labels ? 'block' : 'hidden'} box-border mb-16 absolute bottom-0 w-auto ${textSize.blockWidth} left-[50%] px-5 py-3 my-5`} > 
                <div className={`block text-center ${textSize.headline} overflow-hidden z-10 ${mapFont === 'Monoton'? 'font-normal':'font-bold'}`}>
                    {headline? headline : mapLocation?mapLocation.city: 'New York'}
                    </div>
                <div className="block text-center overflow-hidden">
                    <span className={`block-inline relative ${textSize.tagline}`}>{tagline? tagline : mapLocation? mapLocation.country: 'United States'}</span>
                </div>
                <div className={`text-center ${textSize.subtitle} font-light`}>{subtitle? subtitle : mapLocation?`${mapLocation.center.lat} / ${mapLocation.center.long}`: '40.7128 °N / 74.0060 °W'}</div>
            </div>
            )
            },
        layoutImage: '/layout1.png',
    },
    // {
    //     id: 'bottom',
    //     name: 'bottom',
    //     component: ({headline, tagline, subtitle, mapLocation, labels}) => {
    //     return (
    //     <div className={`flex self-end items-center justify-between ${labels ? 'block' : 'hidden'} absolute text-black bg-white z-10 w-full px-2 py-2`}>
            
    //         <div className="text-[1.5em] font-semibold font-mono tracking-wider overflow-hidden">{headline? headline : mapLocation?mapLocation.city: 'New York'}</div>

    //         <div>
    //           <div className="tracking-wider text-end font-semibold text-[0.8em] font-mono">{tagline? tagline : mapLocation? mapLocation.country: 'United States'}</div>      
    //           <div className="font-mono text-[0.7em] font-semibold">{subtitle? subtitle : mapLocation?`${mapLocation.center.lat} / ${mapLocation.center.long}`: '40.7128 °N / 74.0060 °W'}</div>
    //         </div>
            
    //     </div>
    //     )
    //     },
    //     layoutImage: '/layout6.png',
    // }
    // ({headline, tagline, subtitle, mapLocation, labels, textSize}) => {
    //     return (
    //      <div className={`${labels ? 'block' : 'hidden'} absolute self-end  text-black w-full pb-10 px-5 pt-20 bg-gradient-to-b from-transparent via-white/100 to-white/100 h-1/5 z-10`}>
    //         <div className={`block text-center ${textSize.headline} font-bold tracking-wider overflow-hidden`}>{headline? headline : mapLocation?mapLocation.city: 'New York'}</div>
    //         <div className="divider block text-center overflow-hidden">
    //             <span className={`block-inline relative ${textSize.tagline}`}>{tagline? tagline : mapLocation? mapLocation.country: 'United States'}</span>
    //         </div>
    //         <div className={`text-center ${textSize.subtitle} font-light`}>{subtitle? subtitle : mapLocation?`${mapLocation.center.lat} / ${mapLocation.center.long}`: '40.7128 °N / 74.0060 °W'}</div>
    //     </div>   
    //     )
    //     }
    // {
    //     id: 'block',
    //     name: 'block',
    //     component: ({headline, tagline, subtitle, mapLocation, labels, textSize}) => {
    //     return (
    //     <div className={`${labels ? 'block' : 'hidden'} mb-16 absolute bottom-0  text-black bg-white w-auto ${textSize.blockWidth} left-[50%] px-5 pb-2 my-5`} style={{ transform: 'translate(-50%)' }}> 
    //         <div className={`block text-center ${textSize.headline} font-bold tracking-wider overflow-hidden z-10`}>
    //             {headline? headline : mapLocation?mapLocation.city: 'New York'}
    //         </div>
    //         <div className="block text-center overflow-hidden">
    //             <span className={`block-inline relative ${textSize.tagline}`}>
    //                 {tagline? tagline : mapLocation? mapLocation.country: 'United States'}
    //             </span>
    //         </div>
    //         <div className={`text-center ${textSize.subtitle} font-light`}>
    //             {subtitle? subtitle : mapLocation?`${mapLocation.center.lat} / ${mapLocation.center.long}`: '40.7128 °N / 74.0060 °W'}
    //         </div>
    //     </div>
    //     )
    //     },
    //     layoutImage: '/layout1.png'
    // },
]

export  {
        styles,
        sizes,
        layouts,
        fonts,
        currencyLogos
        };
