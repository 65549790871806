
import { FaMap } from 'react-icons/fa';
import { FaPalette } from 'react-icons/fa';
import { IoIosPrint } from "react-icons/io";
import { Link } from 'react-router-dom';

const processSteps = [
  {
    icon: FaMap,
    title: "Select Location",
    description: "Choose any location in the world for your custom map design.",
  },
  {
    icon: FaPalette,
    title: "Customize Design",
    description: "Pick your favorite style, size, and add custom labels to make it yours.",
  },
  {
    icon: IoIosPrint,
    title: "Quality Print",
    description: "We'll print your design on premium paper with archival inks.",
  }
];

const HowItWorks = () => {
  return (
    <section className="py-16 px-4 bg-accent/30 text-text">
      <div className="max-w-6xl mx-auto">
        {/* Section Header */}
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold mb-4">How It Works</h2>
          <p className="text-text max-w-2xl mx-auto">
            Create your perfect map poster in a few simple steps
          </p>
        </div>

        {/* Process Steps - Flexbox Container */}
        <div className="flex flex-col md:flex-row md:justify-between gap-8 relative">
          {/* Connecting Lines */}
          <div className="hidden md:block absolute top-1/2 left-1/4 right-1/4 h-0.5 bg-accent -translate-y-1/2" />
          
          {processSteps.map((step, index) => (
            <div 
              key={index}
              className="relative group flex-1 flex flex-col min-w-0"
            >
              {/* Card */}
              <div className="bg-accent rounded-sm p-8 shadow-sm hover:shadow-md transition-shadow duration-300 relative z-10 h-full">
                {/* Icon Circle */}
                <div className="w-16 h-16 bg-background rounded-full flex items-center justify-center mx-auto mb-6 text-primary group-hover:bg-primary group-hover:text-white transition-colors duration-300">
                  <step.icon className="w-8 h-8" />
                </div>

                {/* Step Number */}
                <div className="absolute -top-3 -left-3 w-8 h-8 bg-primary text-white rounded-full flex items-center justify-center text-sm font-bold">
                  {index + 1}
                </div>

                {/* Content */}
                <h3 className="text-xl font-semibold text-center mb-4">
                  {step.title}
                </h3>
                <p className="text-text text-center">
                  {step.description}
                </p>
              </div>
            </div>
          ))}
        </div>

        {/* CTA Button */}
        <div className="text-center mt-12">
          <button className="bg-primary text-white px-8 py-3 rounded-sm font-semibold hover:bg-secondary hover:text-text transition-colors duration-300 shadow-sm hover:shadow-md">
           <Link to='/poster'>Start Designing</Link>
          </button>
        </div>
      </div>
    </section>
  );
}

export default HowItWorks;